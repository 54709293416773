$(function() {
	$(".ppd_update").on("change",function(){
		update_ppd(this.id);
	});
});

window.update_ppd = function(id) {
		// ppd = party_part_discount
		ppd = id.split("_")						;
		party_category_id		=	ppd[1]		;
		spare_part_category_id	=	ppd[2]		;
		discount_percent		=	document.getElementById(id).value	;	
		
		var path = "/party_part_discounts/" +  "add_update"		;
				
		$.ajax({	url: path,
					type: "put",
					data: {"party_category_id"			: 	party_category_id,
							"spare_part_category_id"	: 	spare_part_category_id,
							"discount_percent"			: 	discount_percent
							},
					dataType: "json", 
					success: function(result){
					}
		});
};
