$(function() {
	// CHAGE ROW COLOR OF SPARE ADDED PART
		$('.km_changed').change(function(){
			start_km	=	parseInt($("#ta_da_start_km").val());
			end_km		=	parseInt($("#ta_da_end_km").val());
			
			$("#ta_da_km_covered").val(end_km - start_km);
		});

});
