import jsQR from "jsqr-es6";

$(function() {
	if($("#search_spare_qr_cam").length > 0) {
		navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } })
		    .then(function(stream) {
			var video = document.getElementById('search_spare_qr_cam');
			video.srcObject = stream;
		
				video.onloadedmetadata = function(e) {
				    video.play();
				    //video.style.transform = "translate(-50%, -50%) scale(2)";
				    requestAnimationFrame(cam_spare_search);
				};
		    })
		    .catch(function(err) {
				console.log('Error accessing the camera: ', err);
		    });
	}
});

function cam_spare_search() {
	var video = document.getElementById('search_spare_qr_cam');	
    var canvasElement = document.getElementById('canvas');	
    var canvas = canvasElement.getContext('2d');

    canvasElement.width 	= video.videoWidth;
    canvasElement.height 	= video.videoHeight;

	//canvasElement.width 	= 400;
	//canvasElement.height 	= 500;

    canvas.drawImage(video,50 ,100 ,300 , 500 , 0, 0, canvasElement.width, canvasElement.height);

    var imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
    var code =  jsQR(imageData.data, imageData.width, imageData.height, {	inversionAttempts: "dontInvert"    });

	var markup = ""	;
	
    if (code) {
    	if(code.data != ''){
    		video.pause();
    		find(code.data)	;	
    	}
	    else{
	    	requestAnimationFrame(cam_spare_search);
	    }		
    }
	else{
	    requestAnimationFrame(cam_spare_search);
	}
}

function find(scan_code){
		var path = "/spare_parts" +  "/get_scan"	;
			setTimeout(function(){
	  			$.ajax({url: path,
					type: "get",
					data: {"audit_spare_part[scan_code]"	: scan_code
							},
					dataType: "json", 
					success: function(result){
        						 $("#spare_part_num").text(result.part_num)			;
        						 $("#spare_part_qty").text(result.qty)				;
        						 $("#spare_part_desc").text(result.part_desc)		;
        						 $("#spare_part_location").text(result.part_loc)	;
					},
    			});
			}, 100);

}


$(function() {
	if($("#videoElement").length > 0) {
		navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } })
		    .then(function(stream) {
			var video = document.getElementById('videoElement');
			video.srcObject = stream;
		
				video.onloadedmetadata = function(e) {
				    video.play();
				    requestAnimationFrame(tick);
				};
		    })
		    .catch(function(err) {
				console.log('Error accessing the camera: ', err);
		    });
	}
});

// Function to capture video frames and decode QR codes
function tick() {
    var video = document.getElementById('videoElement');	
    var canvasElement = document.getElementById('canvas');	
    var canvas = canvasElement.getContext('2d');

    canvasElement.width = video.videoWidth;
    canvasElement.height = video.videoHeight;
    canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);

    var imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
    var code = jsQR(imageData.data, imageData.width, imageData.height, {	inversionAttempts: "dontInvert"    });

	var markup = ""	;
	
    if (code) {
    	if(code.data != ''){
				markup = "<tr class = 'table-info' >" + "<td>"  + code.data	+ "</td>"	+	"</tr>"	;
				var tableBody = $("#qr_table"); 
        		tableBody.prepend(markup);
			    video.pause()	;
    	}
	    else{
	    	requestAnimationFrame(tick);
	    }		
    }
	else{
	    requestAnimationFrame(tick);
	}
}