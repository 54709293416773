$(function() {
  $("#add_spare_transaction_sale_return").keypress(function(event){
  		var keycode = (event.keyCode ? event.keyCode : event.which);

		if(keycode == '13'){
			sale_return_id = $("#sale_return")[0].value	;
			scan_code 			= $("#add_spare_transaction_sale_return")[0].value.toUpperCase()		;
			$("#add_spare_transaction_sale_return")[0].value	= ""	;
			
			var path = "/sale_returns/" + sale_return_id +  "/save_spare_transaction"	;
			
			$.ajax({url: path,
					type: "post",
					data: {"spare_transaction[scan_code]"	: scan_code
							},
					dataType: "json", 
					success: function(result){
					
						markup = "<tr>"+
									 "<td>"  + result.read_code 		+ "</td>"	+
									 "<td>"  + (result.inc_id ? "DC FOUND" : "DC NOT FOUND") 		+ "</td>"	+
	        						 "<td>"  + result.part_no 		+ "</td>"	+
	        						 "<td>"  + result.qty 			+ "</td>"	+
	        						 "<td>"  + result.batch_code 	+ "</td>"	+
	        						 "<td>"  + result.mrp 			+ "</td>"	+
	        					 "</tr>"; 
						
						tableBody = $("#table_body_spare_transaction");
						tableBody.prepend(markup);
					},

	    			error: function (jqXHR, text, errorThrown) {
        				alert(jqXHR.responseJSON.error);
    				}
    			});
					
	  }
  });
});
