window.inc = function(id) {
	text_field 			= 	document.getElementById(id);
	current_value 		=	text_field.value	;
	current_value 		=	parseInt(current_value)					;
	value				=	current_value + 1; 
	text_field.value 	=	value;
};


window.dec = function(id) {
	text_field 			= 	document.getElementById(id)		;
	current_value 		=	text_field.value				;
	current_value 		=	parseInt(current_value)			;
	value				=	current_value - 1; 
	
	text_field.value 	=	(value >= 0) ? value : 0		;
};
