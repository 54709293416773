$( function() {
  $(".js_process").click(function(event){
		
  		event.preventDefault();
  		event.stopImmediatePropagation()	;
	 	
	 	path = this.href	;
	 	type = this.getAttribute("data-method")	;
	 	
	 	$.ajax({url: path,
				type: type,
				dataType: "json",
				success: function(result){ location.reload();},
				error: function (jqXHR, text, errorThrown) { alert("ERROR OCCURED !");}
		});
  });  
});