$(document).ready(function(){
  $("#audit_spare_code").keypress(function(event){
  		var keycode = (event.keyCode ? event.keyCode : event.which);
  		var scan_code	;
  		var stock_audit_id	; 
		if(keycode == '13'){

			stock_audit_id = $("#stock_audit_id")[0].value	;
			scan_code = $("#audit_spare_code")[0].value.toUpperCase()		;
			employee  = $("#employee")[0].value	;
			rack	  = $("#rack")[0].value		;
			bin		  = $("#bin")[0].value		;
			
			if(scan_code.search("DRLOC-") >= 0){
				physical_location = scan_code.replace("DRLOC-", "");
				document.getElementById("bin").value 	= physical_location	;
				document.getElementById("rack").value 	= ""		;
				$("#audit_spare_code")[0].value	= ""	;
				
				return	;
			}
			
			$("#audit_spare_code")[0].value	= ""	;
			var path = "/stock_audits/" + stock_audit_id +  "/add_audit_spare"	;
		  			$.ajax({url: path,
						type: "post",
						data: {"audit_spare_part[scan_code]"	: scan_code	,
								"audit_spare_part[employee]"	: employee	,
								"audit_spare_part[rack]"		: rack		,
								"audit_spare_part[bin]"			: bin		,
								},
						dataType: "json", 
						success: function(result){
							failure = result.id == null ;
		        			row_class  			= failure ? "table-danger" : "table-success"	;
		        			part_class 			= result.part_desc ? "bg_success" : "bg_warning" ;
		        			location_corrent	= (result.rack + result.bin	== result.system_loc) ? "bg_success" : "bg_warning" ;
		        			
		        			markup = "<tr class = '" + row_class + "'>"+
		        						 "<td>"  									+ result.read_code 				+ "</td>"+
		        						 "<td>"  									+ result.part_num 				+ "</td>"+
		        						 "<td>"  									+ result.qty 					+ "</td>"+
		        						 "<td class = '" + part_class 		+ "'>"  + result.part_desc 				+ "</td>"+
		        						 "<td class = '" + location_corrent + "'>"  + result.system_loc 			+ "</td>"+
		        						 "<td>"  									+ result.employee 				+ "</td>"+
		        						 "<td>"  									+ result.rack + result.bin		+ "</td>"+
		        						 "<td>"  									+ result.rack 					+ "</td>"+
		        						 "<td>"  									+ result.bin 					+ "</td>"+
		        					 "</tr>"; 
	                		tableBody = $("tbody"); 
	                		tableBody.prepend(markup);
	                		
	                		failure ? notif(false) : notif(true)	;	 
						},
	    			});
		}
	});
});
