$( function() {
 	if (window.location.pathname.search("new_party_order") > 0) {
		    var availableTags = document.getElementById("part_nos").value.split(",");
		    $( ".part_tags" ).autocomplete({
		      source: availableTags
		    });
	}
	
	if (/party_orders\/[0-9]*\/edit/.test(window.location.pathname)) {
		    var availableTags = document.getElementById("part_nos").value.split(",");
		    $( ".part_tags" ).autocomplete({
		      source: availableTags
		    });
	}
	
} );

$( function() {
 	if (window.location.pathname.search("new_party_order") > 0) {
				$( ".part_tags" ).blur(function() {
	 				var spare_part_nos 		=	document.getElementById("part_nos").value.split(",");
	 				var spare_part_descs	=	document.getElementById("part_descs").value.split(",");
	 				var spare_part_mrps 	=	document.getElementById("part_mrps").value.split(",");

					part_no	= 	this.value	;
					temp_id	=	this.id.split("_")[3]	;

					part_no_index		=	spare_part_nos.indexOf(part_no)	;
					part_desc			=	spare_part_descs[part_no_index]		;
					part_mrp			=	spare_part_mrps[part_no_index]		;	
					if(part_no_index > -1){
						// PART FOUND IN DATA LOADED						
						$("#part_desc_"+temp_id).text(part_desc + " , MRP = " +  part_mrp);
					}
					else{
						// PART NOT FOUND IN LOADED DATA
						party_id	=	document.getElementById("shoppy_id").value	;
						$.ajax({url: "/spare_parts/search_num",
								type: "get",
								data: {"part_num"	: part_no,
										"party_id"	: party_id},
								dataType: "json", 
								success: function(spare_part){ $("#part_desc_"+temp_id).text(spare_part ? spare_part.part_desc : "PART NOT FOUND !");	},
						});
					}
				});
	}
} );

$( function() {
 	if (window.location.pathname.search("new_party_order") > 0) {
				$( ".part_tags" ).blur(function() {
						part_no		= 	this.value	;
						party_id	=	document.getElementById("shoppy_id").value	;
						temp_id		=	this.id.split("_")[3]	;
						
						$.ajax({url: "/party_orders/previous_order_delivery",
								type: "get",
								data: {"part_num"	: part_no	,
										"party_id"	: party_id
										},
								dataType: "json", 
								success: function(order_vs_delivery){ $("#order_vs_delivery_"+temp_id).text("ORDERED : " + order_vs_delivery.ordered + ", DELIVERED : " + order_vs_delivery.delivered + ", In " + order_vs_delivery.days + " Days");	}
						});
				});
	}
} );

$( function() {
		    $( ".add_rows" ).on("click",function(){
			id = this.id				;
			temp_id = id.split("_")[3]		;
			temp_id	= parseInt(temp_id)		;
			next_row_id = "row_" + (temp_id + 1)	;
			$("#" + next_row_id).show()		;
			//focus_location = "spare_part_order_" + (temp_id + 1) + "_[part_no]"	;
			focus_location = "spare_part_order_" + (temp_id + 1)
			$("#"+focus_location).focus()		;
			event. preventDefault()			;
		    });
} );

