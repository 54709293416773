$(function() {
		     $(".ssp_change").on("change",function(){
		     	var element_id	= this.id					;
		     	var ssp_id		= element_id.split("_")[3]		;
		     	
		     	var elemet_prefix	=	"#system_spare_part_" + ssp_id + "_"	;
		     	
		     	var	tax_type  = $("#tax_type").value			;
				qty_element = $(elemet_prefix + "qty")			;
				mrp_element = $(elemet_prefix + "mrp")			;
				
				discount_percent_element 	= $(elemet_prefix + "discount_rate")	;
				discount_amt_element 		= $(elemet_prefix + "discount_amt")		;
				
				basic_amt_element		= $(elemet_prefix + "basic_amt")		;
				invoice_value_element	= $(elemet_prefix + "invoice_value")	;
				
				igst_rate_element	= $(elemet_prefix + "igst_rate")	;
				cgst_rate_element	= $(elemet_prefix + "cgst_rate")	;
				sgst_rate_element	= $(elemet_prefix + "sgst_rate")	;
				
				igst_amt_element	= $(elemet_prefix + "igst_amt")		;
				cgst_amt_element	= $(elemet_prefix + "cgst_amt")		;
				sgst_amt_element	= $(elemet_prefix + "sgst_amt")		;
				
				var qty 		= parseFloat(qty_element.val())	;
				var mrp		 	= parseFloat(mrp_element.val())	;
				
				var dis_per		= parseFloat(discount_percent_element.val())	;
				var dis_amt		= parseFloat(discount_amt_element.val())		;
				
				var basic_amt 		= parseFloat(basic_amt_element.val())		;
				var invoice_value 	= parseFloat(invoice_value_element.val())	;
				
				var igst_rate 	= parseFloat(igst_rate_element.val())	;
				var cgst_rate 	= parseFloat(cgst_rate_element.val())	;
				var sgst_rate 	= parseFloat(sgst_rate_element.val())	;
				
				var igst_amt 	= parseFloat(igst_amt_element.val())	;
				var cgst_amt 	= parseFloat(cgst_amt_element.val())	;
				var sgst_amt 	= parseFloat(sgst_amt_element.val())	;
				
				unit_rate 		= 	(mrp/qty).toFixed(2)									;
				dis_amt			=	(qty * unit_rate * dis_per/100).toFixed(2)				;
				invoice_value 	=	(qty * unit_rate * (100 - dis_per)/100).toFixed(2)		;	// EXCLUDING DISCOUNT 
				basic_amt		=	((invoice_value * 100)/( 100 + igst_rate)).toFixed(2)	;	// EXCLUDING GST AND DISCOUNT
				igst_amt		=	(basic_amt	*	igst_rate/100).toFixed(2)				;
				cgst_amt		=	(basic_amt	*	cgst_rate/100).toFixed(2)				;
				sgst_amt		=	(basic_amt	*	sgst_rate/100).toFixed(2)				;
				
				
				invoice_value_element.val(invoice_value)	;
				basic_amt_element.val(basic_amt)			;
				discount_amt_element.val(dis_amt)			;

				igst_amt_element.val(igst_amt)		;
				cgst_amt_element.val(cgst_amt)		;
				sgst_amt_element.val(sgst_amt)		;
				
	});
});