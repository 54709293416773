$(function() {
	// CHAGE ROW COLOR OF SPARE ADDED PART
		$('.update_entry_party_followup').change(function(){
			input_field 		= 	this		;
			attr				=	this.name	;
			attr_val			=	this.value	;
			party_followup_id	=	this.id.split("_")[0]	;
			var path 			= 	"/pinnacle/update_party_followup"	;
					
			$.ajax({	url: 	path,
						type: 	"put",
						data: 	{	"attribute_name"			: 	attr				,
									"attribute_value"			: 	attr_val			,
									"party_followup_id"			: 	party_followup_id
								},
						dataType: "json", 
						success: function(result){
							input_field.style.color 	= 	"GREEN"	;
						}
			});
		});
});