function startTime() {
    var today = new Date();
    var h = today.getHours();
    var m = today.getMinutes();
    var s = today.getSeconds();
    m = checkTime(m);
    s = checkTime(s);
	$("#time_div").text(h + ":" + m + ":" + s);
    setTimeout(startTime, 1000);
}

function checkTime(i) {
    if (i < 10) {
    	i = "0" + i	;
    	};  // add zero in front of numbers < 10
    return i;
}

$(document).ready(function () {
	if (/(attendances)+(.)(\d)+/.test(window.location.pathname))
	{
			if (/(attendances)(.)(\d)(\d)*(.)(edit)/.test(window.location.pathname))
			{}
			else
			{
				window.setTimeout(function(){
					history.go(-1);
				}, 3000);
			}
	}
});


$(document).ready(function() {
 	startTime();
});