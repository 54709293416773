$(function() {
    // WILL LOAD AUTOMITICALLY AFTER PAGE LOAD
    setTimeout(function() {
        $(".hide_2").hide('blind', {}, 500);
    }, 2000);
});

$(function() {
	$(".date_picker").datepicker({
		dateFormat: "yy-mm-dd"
	});
});

  $(function() {
    $( ".datepicker_month_year" ).datepicker({
      changeMonth: true,
      changeYear: true,
	  dateFormat: "yy-mm-dd"
    });
  });
  
$(function () {
    $('.boot_select').selectpicker();
});

$(document).ready(function(){
  $("#table_search").on("keyup", function() {
    var value = $(this).val().toLowerCase();
    $("#table_row tr , .table_search_row tr").filter(function() {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    });
  });
});

$(document).ready(function(){
	$("#table_search_option").on("change",function(){
			var value = $(this).val().toLowerCase();
			$("#table_row tr").filter(function() {
				$(this).toggle($(this).children()[0].textContent.toLowerCase().indexOf(value) > -1);
		    });
	});
});

$(function() {
	$("#add_spare_transaction").focus(function(){
	  	$("#add_spare_transaction_message").text("START SCAN OF SPARE PARTS");
	});
	
	$("#add_spare_transaction").focusout(function(){
	  	$("#add_spare_transaction_message").text("SELECT TEXT BOX BELOW BEOFRE SCANNING.");
	});
	
});

$(function() {
	$(".navbar-toggler").click(function(){
	  $(".link_div").toggle();
	});
});

$(function() {
	$(".js_required").prop('required',true);
});

window.notif =	function(success) {
					add_class = success ? "bg_green" : "bg_red"	; 
					setTimeout(function (){document.getElementById("notif").classList.add(add_class)	;}, 100)	;
					setTimeout(function (){document.getElementById("notif").classList.remove(add_class)	;}, 2000)	;
					success	? sound_notify_success() : sound_notify_failure()	;
};

$(function() {
	$( ".notify_table_row" ).click(function() {
		notify_id  = $(this).attr("notify_id")	;
		notify_table_row(notify_id)	;
	});
});

$(function() {
	 $('.bulk_check_exec').change(function() {
        // Check if the toggle-all checkbox is checked
        var isChecked = $(this).is(':checked');
        
        // Set the state of all other checkboxes to match the toggle-all checkbox
        $('.bulk_check').prop('checked', isChecked);
    });
});


window.notify_table_row = function(table_row_id) {
							temp_class = "table-danger"	;
							table_row = document.getElementById(table_row_id)	;
							
							table = table_row.parentNode;
							table = table.insertBefore(table_row, table.firstChild);
							
							current_classes = Array.from(table_row.classList)	;
								
							setTimeout(function (){table_row.classList.add(temp_class)		;}, 100)	;
							setTimeout(function (){table_row.classList.remove(temp_class)	;}, 1000)	;
							
};

window.notify_text = function(text) {
					setTimeout(function (){$("#notif_text").text(text)	;}, 100)	;
					setTimeout(function (){$("#notif_text").text("")	;}, 4000)	;
};

window.show_alert =	function(success) {
					alert("ALERT");
};

window.sound_notify_success =	function() {
		audio_path = "/assets/success-1.mp3" ;
        var audio = new Audio(audio_path); 
        audio.play();
};
   
window.sound_notify_failure =	function() {
		audio_path = "/assets/error-1.mp3"	;
        var audio = new Audio(audio_path); 
        audio.play();
};

