jQuery(function(){
	$( ".lost_sale_status" ).change(function() {
	  lost_sale_id = this.id	;
	  status = $("#"+this.id).val()	;
	  path = "/lost_sales/" + lost_sale_id +  "/update_status"	;
	  	$.ajax({url: path,
				type: "post",
				data: {"lost_sale[status]" : status},
				dataType: "json", 
				success: function(result){
        			alert("Satus for Customer : "+result.status)	;
    			}});
	  
	});
});