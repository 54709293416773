window.getLocation = function() {
	navigator.geolocation.getCurrentPosition(showPosition);
}	;

window.showPosition = function (position) {
	  alert([position.coords.latitude , position.coords.longitude]) ;
}	;


window.getLocationC = function() {
	navigator.geolocation.getCurrentPosition(function (position) {
	  var coordinates = [position.coords.latitude , position.coords.longitude] ;
	  return (coordinates) ;
	});
};


window.getGeoCoordinates = function() {
    return new Promise(function(resolve, reject) {
        if(navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(position){
                var coordinates = [position.coords.latitude, position.coords.longitude];
                resolve(coordinates);
            }, function(error){
                reject("Error occurred: " + error.message);
            });
        } else {
            reject("Geolocation is not supported by this browser.");
        }
    });
};

window.updatePhysicalDeliveryCoordinates = function(physical_delivery_id) {
	 if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(function (position) {
			latitude = position.coords.latitude	;
			longitude = position.coords.longitude ;
		
			$.ajax({url: "/physical_deliveries/" + physical_delivery_id + "/update_coordinates",
					type: "get",
					dataType: "script",
					data: {"latitude" : latitude , "longitude" : longitude },
					success: function(result){ },
					error: function (jqXHR, text, errorThrown) { }
			});
		});
 	}
};

window.my_function = function(){
	alert(1);	
};