$(function() {
  $("#add_spare_transaction_container").keypress(function(event){
  		var keycode = (event.keyCode ? event.keyCode : event.which);

		if(keycode == '13'){
			container_id 		= $("#container")[0].value	;
			scan_code 			= $("#add_spare_transaction_container")[0].value.toUpperCase()	;
			
			$("#add_spare_transaction_container")[0].value	= ""	;
			
			var path = "/containers/" + container_id +  "/save_spare_transaction"	;
			
			$.ajax({url: path,
					type: "post",
					data: {"spare_transaction[scan_code]"	: scan_code
							},
					dataType: "json", 
					success: function(result){
						row_class = result.dec_id ? "table-success" : "table-warning" ;
						
						markup = "<tr class = '" + row_class + "'>"+
									 "<td>"  + result.read_code 		+ "</td>"	+
									 "<td>"  + "" 		+ "</td>"	+
									 "<td>"  + (result.dec_id ? result.delivery_challan : "DC NOT FOUND") 		+ "</td>"	+
	        					 "</tr>"; 
						
						tableBody = $("#table_body_spare_transaction");
						tableBody.prepend(markup);
						
						result.dec_id	?	notif(true)		: 	notif(false)	;
					},

	    			error: function (jqXHR, text, errorThrown) {
        				alert(jqXHR.responseJSON.error);
    				}
    			});
					
	  }
  });
});


$(function() {
  $("#load_container").keypress(function(event){
  		var keycode = (event.keyCode ? event.keyCode : event.which);

		if(keycode == '13'){
			route_plan_id 			= $("#route_plan_id").val()			;
			delivery_vehicle_id 	= $("#delivery_vehicle_id").val()	;
			container_num			= $("#load_container").val()		;
			
			$("#load_container")[0].value	= ""	;
			
			var path = "/containers/load"	;
			
			$.ajax({ url: path,
					 type: "put",
					 data: { "container_num"			: container_num,
							"route_plan_id"				: route_plan_id,
							"delivery_vehicle_id"		: delivery_vehicle_id
						  },
					dataType: "json", 
					success: function(container){
							// DISPLAY NOTIFICATION AFTER SCANNING IS DONE
							(container.id != null) ? notif(true)	: notif(false) ;
							notify_text(container.notification)		;
							update_inc(container.increment)			;
							
							if	(container.id == null){
								$(".toast").toast('show');
								
							}
					},

	    			error: function (jqXHR, text, errorThrown) {
        					alert("ERROR OCCURED !");
        					$(".toast").toast('show');
    				}
    			});
					
	  }
  });
});

window.update_inc =	function(increment) {
		scanned = $("#scanned_con").text();
		pending	= $("#pending_con").text();
		
		scanned = parseInt(scanned)		+	increment	;
		pending = parseInt(pending)		-	increment	;
		
		$("#scanned_con").text(scanned)	;
		$("#pending_con").text(pending)	;
};