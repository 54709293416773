$(function() {
  $("#purchase_spare_transaction").keypress(function(event){
  		var keycode = (event.keyCode ? event.keyCode : event.which);

		if(keycode == '13'){
			purchase_invoice_id = $("#purchase_invoice")[0].value	;
			scan_code 			= $("#purchase_spare_transaction")[0].value.toUpperCase()		;
			container			= $("#container_num")[0].value.toUpperCase()		;
			
		// IF SCAN CODE IS LARGER THAN EXPECTED
			if(scan_code.length <= 500){
					$("#purchase_spare_transaction")[0].value	= ""	;
					
					var path = "/purchase_invoices/" + purchase_invoice_id +  "/save_spare_transaction"	;
					
					$.ajax({url: path,
							type: "post",
							data: {"spare_transaction[scan_code]"	: scan_code,
									"spare_transaction[container]"  : container
									},
							dataType: "json", 
							
							success: function(result){
								if(result.type == "CONTAINER"){
									// CONTAINER ROW COLOUR DEPENDS IF  CONTAINER IS UPLOADED IN PURCHASE
									
									$("#container_num")[0].value = result.cont_num;
									row_colour 	= 	result.container_uploaded	? "table-success" 	: "table-danger"		;
									cont_excess	=	result.container_uploaded	? "PRESENT"			: "NOT IN SHIPLIST"		;
									markup = "<tr " + "class = " + row_colour + ">"+
												 "<td>"  + result.cont_num						+ "</td>"	+
				        						 "<td colspan = 3>"  + "THIS IS A CONTAINER"	+ "</td>"	+
				        						 "<td colspan = 4>"  + cont_excess				+ "</td>"	+
				        					 "</tr>"; 
			                		tableBody = $("#table_body_spare_transaction"); 
			                		tableBody.prepend(markup);
			                		notif(true)	;
								}
								else if(result.type == "SPARE PART"){
									markup = "<tr>"+
				        						 "<td>"  + result.part_no 		+ "</td>"	+
				        						 "<td>"  + ""	+ "</td>"	+
				        						 "<th class = 'font_200'>"  + result.location	 	+ "</th>"	+
				        						 "<td>"  + result.part_desc		+ "</td>"	+
				        						 "<td>"  + result.qty 			+ "</td>"	+
				        						 "<td>"  + ""	+ "</td>"	+
				        						 "<td>"  + result.container 	+ "</td>"	+
				        						 "<td>"  + ""	+ "</td>"	+
				        					 "</tr>"; 
			                		tableBody = $("#table_body_spare_transaction"); 
			                		tableBody.prepend(markup);
			                		
			                		$("#alt-locations").val(result.alt_locations)	;
			                		$("#spare_part_id").text(result.part_no)		;
			                		
			                		if(result.sale_orders){
				                		alert_message = "SALE ORDER \r\n" + result.sale_orders;
				                		alert(alert_message);
			                		}
			                		
			                		notif(true)	;
			                		update_scanned_qty(result.spare_part_id, result.qty , "+")	;
			                		notify_table_row("spare_part_row_" + result.spare_part_id)	;
								}
								
								else{
									notif(false)	;	
								}
								
								
							},
		
			    			error: function (jqXHR, text, errorThrown) {
		        				//alert(jqXHR.responseJSON.error);
		        				notif(false)	;
		    				}
		    			});
			}				
	  }
  });
});


window.update_scanned_qty =	function(spare_part_id, qty, update_type) {
	current_qty = $("#scanned_spare_part_id_" + spare_part_id).text()	;
	current_qty = parseInt(current_qty)	;
	new_qty		= current_qty + qty				;
	$("#scanned_spare_part_id_" + spare_part_id).text(new_qty)		;
};


function show_notification(key,value){
	document.getElementById("main_model_display").textContent = value	;
	$("#main_model").modal('show');
}

$(function() {
    // WILL LOAD AUTOMITICALLY AFTER PAGE LOAD
    setTimeout(function() {
        $("#main_model").modal('hide');
    }, 2000);
});

