$(function() {
	// CHAGE ROW COLOR OF SPARE ADDED PART
		$('.amt_change').change(function(){
			currency_value = this.getAttribute("amt");
			currency_count = parseInt(this.value);
			amt = currency_value * currency_count;
			$("#" + "count_" + currency_value).val(amt);
			calculate_total();
		});

});

window.calculate_total = function() {
	currencies_elements = $('.amt_change');
	total = 0	;
	
	for(i=0; i<currencies_elements.length; i++){
		element = 	currencies_elements[i];
		
		currency_value = element.getAttribute("amt")	;
		currency_count = parseInt(element.value)		;
		amt = currency_value * currency_count			;
		
		total = total + amt	;
	}

	$("#cash_deposit_total").val(total)	;
};
