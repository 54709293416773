$(document).ready(function(){
  $("#find_scan_spare_part").keypress(function(event){
  		var keycode = (event.keyCode ? event.keyCode : event.which);
  		var scan_code	;
		if(keycode == '13'){

		scan_code = $("#find_scan_spare_part")[0].value.toUpperCase()		;
		
		$("#find_scan_spare_part")[0].value	= ""	;
		var path = "/spare_parts" +  "/get_scan"	;
			setTimeout(function(){
	  			$.ajax({url: path,
					type: "get",
					data: {"audit_spare_part[scan_code]"	: scan_code
							},
					dataType: "json", 
					success: function(result){
	        			part_class = result.part_desc ? "bg_success" : "bg_warning" ;
	        			markup = "<tr class = 'success'>"+
	        						 "<td>"  								+ result.read_code 			+ "</td>"+
	        						 "<th class = 'font_200'>"  			+ result.part_num 			+ "</th>"+
	        						 "<td>"  								+ result.qty 				+ "</td>"+
	        						 "<td class = '" + part_class + "'>"  	+ result.part_desc 			+ "</td>"+
	        						 "<th class = 'font_200'>"  			+ result.part_loc			+ "</th>"+
	        						 "<td>"  								+ result.scanned_at			+ "</td>"+
	        					 "</tr>"; 
                		tableBody = $("tbody"); 
                		tableBody.prepend(markup); 
					},
    			});
			}, 100);
		}
	});
});

$( function() {
		    $( ".spare_part_db_search" ).autocomplete({
				
				source: function(request, response) {
                    $.ajax({
                        url: "/spare_parts/search_nums",
                        type: "get", 
                        dataType: "json",
                        data: {
                            search: request.term // Pass user input as a query parameter
                        },
                        success: function(data) {
                            // Process data and pass it to response callback
                            response(data);
                        }
                    });
                },
                minLength: 2 // Minimum characters before autocomplete activates
		    });
});
