$(function() {
		     $(".ledger_search_change").on("change",function(){
		     	
				var day_count_search = parseInt($("#ls_days").val())	;
				var type_search 	 = $("#ls_type").val()					;
				if (isNaN(day_count_search))
					return;
				 	
						
				$("#ledger_table tr").filter(function() {
					if (type_search == "MORE THEN"){							
							$(this).toggle(  parseInt($(this).attr("day_count") ) >  day_count_search ) ;
							$(this).attr('class', '')	;
							$(this).addClass("table-warning");
					}
					else if (type_search == "LESS THEN"){
							$(this).toggle(  parseInt($(this).attr("day_count") ) <=  day_count_search ) ;
							$(this).attr('class', '')	;
							$(this).addClass("table-success");	
					}
					else{
						$(this).show();
						$(this).attr('class', '')	;
						$(this).addClass("table-info");
					}
					 
			    });
	});
	
	$(".cd_int_change").on("change",function(){
		var	total_cd		=	0	;
		var total_interest	=	0	;

		$("#ledger_table tr").filter(function() {
			var cd_days 			= parseInt($("#cd_days").val())			;
			var cd_percent			= parseFloat($("#cd_percent").val())		;
			
			var interest_days		= parseInt($("#intr_skip_days").val())		;
			var interest_percent	= parseFloat($("#intr_percent").val())		;
			
			var row_clear_days 	= parseInt($(this).attr("day_count") )	;
			var debit_amt		= parseFloat($(this).attr("debit_amt") )	;
			
			var per_day_interest 	=	interest_percent/365			;
			var cd_amt 				=	debit_amt*cd_percent/100		;
			
			var interest_amt 		= 	debit_amt*per_day_interest*(row_clear_days - interest_days)/100					;
			
			last_cell 	=	$(this).children().last()					;
			
			
			if(row_clear_days < cd_days){
				(cd_amt > 0) ? last_cell.html("CD :" + cd_amt)	: "" 	;
				total_cd	=	total_cd +  cd_amt						;
			}
			else if(row_clear_days > interest_days){
				(interest_amt > 0) ? last_cell.html("INTEREST :" + interest_amt.toFixed(2))	: "" ;
				total_interest	=	total_interest + interest_amt		;
			}
			else
				last_cell.html("")	;
			
		});
		
		$("#cd_int").html("TOTAL CD :" + total_cd.toFixed(2) + " , TOTAL INTEREST : " + total_interest.toFixed(2))	;
	});
	
	
	$("#hide_lt_10").on("click",function(){
			$(".table-success").hide();
			$(this).hide();
	});
});