$(function() {
  $("#add_spare_transaction").keypress(function(event){
  		var keycode = (event.keyCode ? event.keyCode : event.which);

		if(keycode == '13'){
			delivery_challan_id = $("#delivery_challan")[0].value			;
			scan_code 			= $("#add_spare_transaction")[0].value		;
			container_id		= $("#container_id").val()					;
			
			$("#add_spare_transaction")[0].value	= ""	;
			
			var path = "/delivery_challans/" + delivery_challan_id +  "/save_spare_transaction"	;
			
			$.ajax({url: path,
					type: "post",
					data: {"spare_transaction[scan_code]"		: scan_code		,
							"spare_transaction[container_id]"	: container_id	,
							},
					dataType: "json", 
					success: function(result){
						if(result.type == "CONTAINER"){
								container = result	;
								markup = "<tr class =" + "table-info" + ">"	+
										 	"<td>"  			+ container.uid											+ "</td>"	+
										 	"<td colspan = 5>"  + "SPARE TRANSACTIONS WILL BE ADED TO THIS CONTAINER"	+ "</td>"	+
										 "</tr>"	;
								tableBody = $("#table_body_spare_transaction"); 
		                		tableBody.prepend(markup);
		                		
		                		// COLLECT ALL CONTAINER IDS
		                		container_ids = new Array()	;
		                		$('#container_id option').each(function(){ container_ids.push(this.value); })	;
		                		
		                		// CHECK IF THE CONTAINER IS LOADED ON THIS DELIVERY CHALLAN
	                			cotainer_found = $.inArray(container.id.toString(),container_ids) !== -1	;
	                			
	                			if(cotainer_found){
			                		// if container is present on page, select container
									$('#container_id').val(container.id)	;	                				
	                			}
		                		
		                		else{
			                		// if container is not present on page, load new page
		                			//window.location.href = "/delivery_challans/410382/add_spare_transaction?current_container_id="	+ container.id;
		                			
		                			window.location.href =  location.pathname + "?current_container_id="	+ container.id;
		                		}
		                		
						}
						
						else if(result.type == "SPARE PART"){
								date = new Date()	;	
								date_string = date.getDate() +"-" + date.getMonth() + "-" + date.getFullYear() + " " + (date.getHours() % 12) + ":"+ date.getMinutes()	;
								 
								// IF SPARE PART NOT ADDED ie QTY = 0 OR EXCESS SPARE PART IS ADDED THEN TICKET PART pending_qty < 0
								wrong_scan = (result.pending_qty < 0 || result.qty < 0 || result.id == null)	;
								row_colour = wrong_scan ? "table-danger" : "table-success" ;
								
								markup = "<tr class =" + row_colour + ">"	+
											 "<td>"  + result.read_code 							+ "</td>"	+
			        						 "<td>"  + result.part_no 								+ "</td>"	+
			        						 "<td>"  + result.qty 									+ "</td>"	+
			        						 "<td>"  + result.pending_qty							+ "</td>"	+
			        						 "<td>"  + result.batch_code	 						+ "</td>"	+
			        						 "<td>"  + result.mrp 									+ "</td>"	+
			        						 "<td>"  + date_string 									+ "</td>"	+
			        						 "<td>"  + result.cont_num								+ "</td>"	+
			        					 "</tr>"; 
		                		tableBody = $("#table_body_spare_transaction"); 
		                		tableBody.prepend(markup);
		                		
		                		// UPDATE PENDING QTY IS CORRECT SCAN
		                		if(!wrong_scan){
			                		update_ticket_part_picked_qty(result.part_no,result.qty);                			
		                		}
		                		
		                		// CHANGE COLOUR OF NOTIF DIV IF PART IS LESS
								(wrong_scan) ?  notif(false)	: notif(true)		;
																
						}
						else{
							notif(false);
							markup = "<tr class =" + "table-danger" + ">"	+
										 	"<td>"  			+ scan_code					+ "</td>"	+
										 	"<td colspan = 5>"  + "SOMETHING WENT WRONG"	+ "</td>"	+
										 "</tr>"	;
								tableBody = $("#table_body_spare_transaction"); 
		                		tableBody.prepend(markup);
						}
					},

	    			error: function (jqXHR, text, errorThrown) {
        				alert(jqXHR.responseJSON.error);
    				}
    			});
					
	  }
  });
});

function update_ticket_part_picked_qty(part_no,add_qty){
	part_qty_id = "#" + "qty_"+ part_no;
	if($(part_qty_id).length){
		// UPDATE TICKET PARTS		
		prv_qty = parseInt($(part_qty_id).val())	;
		new_qty = prv_qty +  add_qty	;
		$(part_qty_id).val(new_qty)		;
		$(part_qty_id).change()			;
	}
	else{
		// DISPLAY ALERT MESSAGE IF TICKET PARTS TABLE EXIST
		if($("#ticket_parts_table").length){
			alert("SPARE PART NOT PRESENT IN TICKET !!!");			
		}
	}
}

$(function() {
	// CHAGE ROW COLOR OF SPARE ADDED PART
	$('.pick_qty_change').change(function(){
			base_id = this.id.split("_")[1]	;
			allocated_qty_id = "#" + "all_qty_"+ base_id;
			row_id 	= "#" + "row_"+ base_id;
			
			allocated_qty = $(allocated_qty_id).val() 	; 
			picked_qty    = this.value	;
			
			diff = allocated_qty - picked_qty;
			
			$(row_id).removeClass()	;
			if(diff < 0){
				$(row_id).addClass("table-danger")	;
			}
			else if(diff == 0){
				$(row_id).addClass("table-success")	;
			}
			else if(diff > 0){
				$(row_id).addClass("table-warning")	;
			}
			else{
				$(row_id).addClass("table-active")	;;
			} 
	});
});

$(document).ready(function(){
  $("#myInput").on("keyup", function() {
    var value = $(this).val().toLowerCase();
    $("#myTable tr").filter(function() {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    });
  });
});

window.open_window = function(link){
    var myWindow = window.open(link, "", "top=100,left=200,width=1000,height=500");
};

$(function() {
	// CHAGE ROW COLOR OF SPARE ADDED PART
	$('.challan_inv_no').change(function(){
			n = new Date					;
			month = n.getMonth() + 1		;
			year  = n.getFullYear()		;
			day	  = n.getDate()				;
			date = year+"-"+month+"-"+day 	;
			id = this.id					;
			date_id = id.replace("invoice_no", "invoice_date")		;
			$("#"+date_id).val(date)									;
			
	});
});
