$(function() {
	// CHAGE ROW COLOR OF SPARE ADDED PART
		$('#payment_receipt_pay_type').change(function(){
			if(this.value == "CHEQUE"){
				$("#payment_receipt_value_date").show()	;
			}
			else{
				$("#payment_receipt_value_date").hide()	;
			}
		});
});