$(function() {
	if (window.location.pathname.search("qr_codes") > 0) { 		
			var qr;
			(function()	{
				qr = new QRious({
					element: document.getElementById('qr-code'),
					size: 200,
					value: 'htps://www.darkrecto.com'
				});
			})();

			$( "#qr-text" ).change(function() {				
			  	var qrtext = document.getElementById("qr-text").value;
				document.getElementById("qr-result").innerHTML = qrtext;
			
				qr.set({
					foreground: 'black',
					size: 200,
					value: qrtext
				});
			  
			});
		 }
});

$(function() {
	if (window.location.pathname.search("qr_codes") > 0) { 
			var qr_codes = new Array(10);
			
			(function()	{
				for(i=0; i < qr_codes.length; i++)
				{					
					qr_codes[i] = 	new QRious({
										element: document.getElementById('qr_code_'+i),
										size: 200,
										value: 'htps://www.darkrecto.com'
									});	
				}
			})();

			$( ".gen_qr_code" ).change(function() {
				temp		=	this.id.split("_")								;
				id			=	temp[temp.length -1]							;
				part_no 	=	document.getElementById("part_no_"+id).value	;
				qty		 	=	document.getElementById("part_qty_"+id).value	;
				batch_code 	=	document.getElementById("batch_code_"+id).value	;
				mrp 		=	document.getElementById("mrp_"+id).value;
				
				var path = "/qr_codes/" +  "data_to_scan_code"	;

				$.ajax({url: path,
						type: "post",
						data: {	"part_hash[part_no]"	: part_no,
								"part_hash[qty]"		: qty,
								"part_hash[batch_code]"	: batch_code,
								"part_hash[mrp]"		: mrp
								},
						dataType: "json", 
						success: function(result){
						  	qr_string 		=	result["qr_string"];
						  	part_desc		=	result["part_desc"];
						  	scan_code		=	result["scan_code"];

						  	part_no		=	scan_code["part_no"];
						  	qty			=	scan_code["qty"];
						  	batch_code	=	scan_code["batch_code"];
						  	mrp			=	scan_code["mrp"];
						  	

							
							document.getElementById("qr_div_id_"+id).style.display = "inline-block"		;
							
							document.getElementById("qr_result_"+id).innerHTML = part_no + ", <strong>QTY</strong> : " + qty	+ ": " + batch_code;
							document.getElementById("qr_part_desc_"+id).innerHTML = part_desc					;
							
							if(typeof(mrp) != "undefined"){
								document.getElementById("qr_mrp_"+id).innerHTML 		= "<strong>MRP : </strong>" + mrp					;
							}
							
							qr_codes[id].set({
								foreground: 'black',
								size: 200,
								value: qr_string
							});
						
							
						},
	
		    			error: function (jqXHR, text, errorThrown) {
	        				
	    				}
	    		});
			});
	}

});


$(function() {
  		if (window.location.pathname.search("qr_codes") > 0) { 
		     $(".canvas_code").each(function() {
				code	=	this.id.split("_");
				code.shift()	;
				code_id		=	code.join("_")	;
				qr_value = document.getElementById(code_id).value	;

				new QRious({element: document.getElementById(this.id),	size: 90,	value: qr_value	});	
		     });
		}
});

$(function() {
		     $("#canvas_code").each(function() {
				code	=	this.id.split("_");
				code.shift()	;
				code_id		=	code.join("_")	;
				qr_value = document.getElementById(code_id).value	;

				new QRious({element: document.getElementById(this.id),	size: 200,	value: qr_value	});	
		     });
});

$(function() {
		     $(".gen_qr_link").each(function() {
				code	=	this.id.split("_");
				code.shift()	;
				code_id		=	code.join("_")	;
				qr_value = window.location.origin  + document.getElementById(code_id).value	;

				new QRious({element: document.getElementById(this.id),	size: 200,	value: qr_value	});	
		     });
});

$(function() {
			$( ".cont_qr_gen" ).change(function() {				
			  	var from 	= 	parseInt(document.getElementById("from").value)			;
			  	var	to		=	parseInt(document.getElementById("to").value)			;
			  	var user_id	=	parseInt(document.getElementById("user_id").value)		;
			  	
				for(i=from; i < (to+1); i++)
				{					
					value 	=	"CONTAINER-" + i	;
					link	=	window.location.origin + "/containers/search_num?" + "num=" + value	 + "&user_id=" + user_id	;
					
					// CREATING CANVAS DIV
					var container_div		=	document.createElement("div")		;
					container_div.setAttribute("class","m-4 d-inline-block w_200")	;

					// CREATING CANVAS FOR QR
					var qr_canvas			=	document.createElement("canvas")	;
					qr_canvas.setAttribute("id","cont_qr_code_"+i)					;
					
					// ADD CONTAINER NUMBER IN DIV
					var container_text	=	document.createElement("span")			;
					container_text.innerHTML = value								;
					
					// ADDING CANVAS AND CONTAINER NO TO CONTAINER DIV
					container_div.appendChild(qr_canvas)							;
					container_div.appendChild(document.createElement("br"))			;
					container_div.appendChild(container_text)						;
					
					
					// ADDING CONTAINER DIV TO CONTAINERS DIV
					var containers_div	=	document.getElementById("containers_div")	;
					containers_div.appendChild(container_div);
					
					// SET CANVAS FOR QR CODE
					new QRious({
								element: document.getElementById('cont_qr_code_'+i),
								size: 100,
								foreground: 'black',
								value: link
							});	
				}
				
			});
			

});
