$(function() {
	// UPDATE PHYSICAL DELIVERY
	$('.pd_state_chnage').change(function(){
		physical_delivery_id = this.id.split("_")[2];
		status		=	this.value;
		var path = "/physical_deliveries/" + physical_delivery_id +  "/update_status"	;
				
		$.ajax({	url: path,
					type: "get",
					data: {"status"	: status
							},
					dataType: "json", 
					success: function(result){
						alert("DELIVERY STATUS UPDATED");
					}
		});
	});
});

$( function() {
  $(".deliver_with_location").click(function(event){
		
  		event.preventDefault();
  		event.stopImmediatePropagation()	;
	 	
	 	path = this.href	;
	 	type = this.getAttribute("data-method")	;
	 	
	 	updatePhysicalDeliveryCoordinates(this.id) ;
	 	
	 	$.ajax({url: path,
				type: type,
				dataType: "script",
				success: function(result){ location.reload(); },
				error: function (jqXHR, text, errorThrown) { alert("ERROR OCCURED !");}
		});
  });  
});
